import {
  ModalContent,
  useDisclosure,
  ModalCloseButton,
  ModalBody,
  Modal,
  VStack,
  ModalOverlay,
  Text,
} from '@chakra-ui/react';
import { useEffect } from 'react';

export default function ModalModule() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  useEffect(() => {
    onOpen();
  }, []);

  return (
    <Modal onClose={onClose} isOpen={isOpen} isCentered size={'lg'}>
      <ModalOverlay />
      <ModalContent borderRadius={25}>
        <ModalBody>
        <ModalCloseButton size={"lg"} />
          <VStack textAlign={"center"} py={5} spacing={5} fontWeight={'bold'}>
            <Text fontSize={'lg'}>PERÍODO DE PARTICIPAÇÃO ENCERRADO.</Text>
            <Text fontSize={'xl'} fontWeight={'black'}>
              PARABÉNS AO GANHADOR DA PROMOÇÃO!
            </Text>
            <VStack align={'center'} spacing={0}>
              <Text fontSize={'2xl'} fontWeight={'black'}>
                THIAGO LOURO FORESI
              </Text>
              <p>CPF: XXX.XXX.X98-08</p>
              <p>CIDADE: FORTALEZA - CE</p>
              <p>LOJA PIZZA HUT - AEROPORTO DE FORTALEZA</p>
            </VStack>
            <Text fontWeight={'black'}>PROMOÇÃO DÁ UM HUT NO SUPER BOWL</Text>
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
