import Cups from "./components/Cups";
import Form from "./components/Form";
import Header from "./components/Header";
import HowToPlay from "./components/HowToPlay";
import ModalModule from "./components/Modal";
import Prizes from "./components/Prizes";
import Winner from "./components/Winner";
// import WhasAppButon from "./components/WhatsAppButton";


export default function PizzaHutModule() {
  
  return (
    <div>
      <ModalModule/>
      <Header/>
      <Prizes/>
      <Cups/>
      <HowToPlay/>
      <Winner/>
      <Form/>
      {/* <WhasAppButon/> */}
    </div>
  );
}
