import { Box, HStack, Stack, Text, VStack } from '@chakra-ui/react';
import CustomNextImage from '@components/ChakraNextImage';

export default function Prizes() {
  return (
    <VStack bgColor={'#C2C3C9'} p={2} spacing={5}>
      <Stack
        direction={{ base: 'column', lg: 'row' }}
        align={{ base: 'center', lg: 'end' }}
      >
        <Box mr={5}>
          <CustomNextImage
            src={'/imgs/pizza_hut/prizedescription.png'}
            alt={`prizedescription.png`}
            width={'312px'}
            height={'439px'}
          />
        </Box>
        <HStack align={'end'} >
          <CustomNextImage
            src={'/imgs/pizza_hut/prizeticket.png'}
            alt={`prizeticket.png`}
            width={'580px'}
            height={'459px'}
          />
          <CustomNextImage
            src={'/imgs/pizza_hut/prizebox.png'}
            alt={`prizebox.png`}
            width={'334px'}
            height={'353px'}
          />
        </HStack>
      </Stack>
      <Box>
        {/* <CustomNextImage
          src={'/imgs/pizza_hut/descriptionexplanation.png'}
          alt={`descriptionexplanation.png`}
          width={'1193px'}
          height={'39px'}
        /> */}
        <Text
          color="#B1000B"
          fontSize={['10px', '12px']}
          textAlign="center"
          fontWeight={400}
        >
          *VOCÊ E SEU ACOMPANHANTE PRECISAM TER PASSAPORTE COM VISTO PARA OS
          ESTADOS UNIDOS VÁLIDOS PARA PODER SER CONTEMPLADO. <br />
          CONSULTE REGULAMENTO E CONDIÇÕES PARA PARTICIPAR
        </Text>
      </Box>
    </VStack>
  );
}
