import {
  Button,
  Center,
  FormControl,
  FormHelperText,
  FormLabel,
  Image,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  SimpleGrid,
  Text,
  VStack,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { DynamicFields } from '@components/forms/DynamicFields';
import { messageTextAreaName } from '@data/conts';
import { fieldsContact } from '@data/form';
import logger from '@logger/logger';
import { StatusRespEmail, postEmailReq } from '@services/emails';
import { FormProvider, useForm } from 'react-hook-form';
import ReCAPTCHA from 'react-google-recaptcha';
import { useRef, useState } from 'react';
import { motion } from 'framer-motion';

export default function Form() {
  const formMethods = useForm();
  const {
    handleSubmit,
    formState: { isSubmitting, errors, isValid },
    setError,
    clearErrors,
    reset,
  } = formMethods;
  const toast = useToast();
  const recaptchaRef = useRef<ReCAPTCHA | null>();
  const [recaptchCode, setRecaptchCode] = useState<string | null>();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleValidateQuestionAnswer = async (dataform: any, error: any) => {
    logger.debug(dataform);
    logger.error(error);
    if (recaptchCode == undefined || recaptchCode == null) {
      setError('recaptcha', { message: 'Recaptcha é obrigatorio' });
      return;
    } else {
      clearErrors('recaptcha');
    }

    if (dataform != null || dataform != undefined) {
      try {
        let body: any = {
          recaptchCode,
        };

        fieldsContact.forEach((field) => {
          if (
            dataform[field.fieldName] != undefined &&
            field.formatOut != undefined
          ) {
            body[field.fieldName] = field.formatOut(dataform[field.fieldName]);
          } else {
            body[field.fieldName] = dataform[field.fieldName];
          }
        });

        const statusResp = await postEmailReq(body);

        if (statusResp == StatusRespEmail.Success) {
          reset();
          toast({
            title: 'Sucesso',
            description: 'Mesagem enviada com exito!',
            status: 'success',
            duration: 9000,
            isClosable: true,
            position: 'bottom',
          });
        } else if (statusResp == StatusRespEmail.FailRecaptch) {
          toast({
            title: 'Erro',
            description:
              'Erro ao validar reCaptch, tente novamente mais tarde!',
            status: 'error',
            duration: 9000,
            isClosable: true,
            position: 'bottom',
          });
        } else {
          toast({
            title: 'Erro',
            description:
              'Erro no envio da mensagem, tente novamente mais tarde!',
            status: 'error',
            duration: 9000,
            isClosable: true,
            position: 'bottom',
          });
        }
      } catch (e) {
        logger.error(e);
      } finally {
        setRecaptchCode('');
        onClose();
        recaptchaRef.current?.reset();
      }
    }
  };

  const changeRecaptch = (token: string | null) => {
    setRecaptchCode(token);
    clearErrors('recaptcha');
  };

  return (
    <VStack
      p={5}
      bgColor="#B1000B"
      align={'center'}
      justify={'center'}
      spacing={10}
    >
      <Center
        bgColor="#D9D9D9"
        height="48px"
        color="#A10000"
        borderRadius={25}
        px={50}
        as={motion.div}
        dragConstraints={{ left: -100, right: 100 }}
        whileHover={{ scale: 1.2 }}
        whileTap={{ scale: 0.9 }}
        transition="0.05s linear"
        cursor={'pointer'}
        onClick={onOpen}
      >
        <Button
          variant="link"
          color="#A10000"
          textDecor="none"
          lineHeight={0}
          _hover={{ textDecor: 'none' }}
          isLoading={isSubmitting}
          isDisabled={isSubmitting && !isValid}
          type="submit"
        >
          FALE CONOSCO
        </Button>
      </Center>
      <Modal
        isCentered
        isOpen={isOpen}
        onClose={onClose}
        scrollBehavior={'inside'}
        size={{ base: 'full', lg: '6xl' }}
      >
        <ModalOverlay />
        <ModalContent bgColor="#c2c3c9" width={'auto'} overflow={'auto'} p={5}>
          <ModalCloseButton color="#DB1C25" />
          <form
            onSubmit={handleSubmit(handleValidateQuestionAnswer, (error) => {
              console.log(error);
            })}
          >
            <VStack
              p={5}
              align={'center'}
              justify={'center'}
              spacing={10}
              // pl={10}
            >
              <FormProvider {...formMethods}>
                <SimpleGrid
                  columns={{ base: 1, lg: 2 }}
                  // spacing={['10', '20']}
                  // mx={'auto'}
                  w={{ base: 'full', lg: 'full' }}
                >
                  {fieldsContact.map((d, i) => {
                    const gridInfos =
                      d.fieldName == messageTextAreaName
                        ? '1 / 3'
                        : i % 2 == 0
                        ? 1
                        : 2;
                    return (
                      <FormControl
                        key={i}
                        isRequired={
                          d.config?.required != undefined &&
                          d.config?.required == true
                        }
                        gridColumn={{ base: 1, lg: gridInfos }}
                      >
                        <VStack
                          align={{ base: 'center', lg: 'start' }}
                          spacing={1}
                        >
                          <FormLabel
                            variant={'formLabel'}
                            color="#B1000B"
                            htmlFor={d.fieldName}
                            m={0}
                            ml={2}
                          >
                            {d.label}
                          </FormLabel>

                          <DynamicFields {...d} />
                          {errors[d.fieldName] == undefined ? (
                            <FormHelperText>
                              {d.helperText != undefined && d.helperText}
                            </FormHelperText>
                          ) : (
                            <Text
                              variant={'formErrorMessage'}
                              w={d.fieldWidth || 'full'}
                              textAlign="center"
                            >
                              {errors[d.fieldName]?.message as string}
                            </Text>
                          )}
                        </VStack>
                      </FormControl>
                    );
                  })}
                </SimpleGrid>
              </FormProvider>

              <VStack>
                <ReCAPTCHA
                  ref={(ref) => (recaptchaRef.current = ref)}
                  sitekey={process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY || ''}
                  onChange={changeRecaptch}
                  hl="pt-BR"
                />
                {errors['recaptcha'] != undefined && (
                  <Text
                    variant={'formErrorMessage'}
                    w={'full'}
                    textAlign="center"
                  >
                    {errors['recaptcha']?.message as string}
                  </Text>
                )}
              </VStack>
              <Center
                bgColor="#DB1C25"
                height="48px"
                color="#FBFCFC"
                borderRadius={25}
                px={10}
                as={motion.div}
                dragConstraints={{ left: -100, right: 100 }}
                whileHover={{ scale: 1.2 }}
                whileTap={{ scale: 0.9 }}
                transition="0.05s linear"
                cursor={'pointer'}
                onClick={handleSubmit(handleValidateQuestionAnswer, (error) => {
                  console.log(error);
                })}
              >
                <Button
                  variant="link"
                  color="#FBFCFC"
                  textDecor="none"
                  lineHeight={0}
                  _hover={{ textDecor: 'none' }}
                  isLoading={isSubmitting}
                  isDisabled={isSubmitting && !isValid}
                  type="submit"
                >
                  ENVIAR
                </Button>
              </Center>
              <Image
                src={'/imgs/pizza_hut/logo_red.png'}
                width={[274]}
                height={[163]}
              />
            </VStack>
          </form>
        </ModalContent>
      </Modal>
      <Image
        src={'/imgs/pizza_hut/logoDescripition.png'}
        width={[205]}
        height={[119]}
      />
    </VStack>
  );
}
