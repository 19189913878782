import httpConfig from '@lib/apiClientFetch';
import logger from '@logger/logger';
import { AxiosError } from 'axios';

/**           POST GAME QUESTION INIT     */

export enum StatusRespEmail {
  Success,
  FailRecaptch,
  FailReq,
}

export const postEmailReq = async (body: any): Promise<StatusRespEmail> => {
  //fetch game detail
  try {
    const response = await httpConfig.fetch.post('/api/emails/contact', body);

    const answerQuestionRes = response.data;
    logger.debug('postEmailReq:', answerQuestionRes);
    if (response.status != 200 && response.status != 201) {
      if (
        answerQuestionRes.code != undefined &&
        answerQuestionRes.code.includes('recaptcha')
      ) {
        return StatusRespEmail.FailRecaptch;
      }

      return StatusRespEmail.FailReq;
    }
    return StatusRespEmail.Success;
  } catch (error) {
    logger.error(error);

    logger.debug("errr",error);
    if (
      error instanceof AxiosError &&
      error.response?.data?.code &&
      error.response?.data?.code.includes('recaptcha')
    ) {
      logger.debug("recaptcha");
      return StatusRespEmail.FailRecaptch;
    }
    logger.debug("FailReq");
    // throw new Error("Failed on AnswerQuestionReq:" + error);
    return StatusRespEmail.FailReq;
  }
};
/**           POST GAME QUESTION END     */
