import { Stack } from '@chakra-ui/react';
import CustomNextImage from '@components/ChakraNextImage';

export default function Cups() {
  return (
    <Stack
      direction={{ base: 'column', lg: 'row' }}
      align={'center'}
      justify={'center'}
      ml={{ base: 0, md: 10 }}
      p={{ base: 5, lg: 8 }}
      spacing={8}
    >
      <CustomNextImage
        src={'/imgs/pizza_hut/cupsdescription.png'}
        alt={`cupsdescription.png`}
        width={'457px'}
        height={'331px'}
      />
      <CustomNextImage
        src={'/imgs/pizza_hut/cups.png'}
        alt={`cups.png`}
        width={'808px'}
        height={'353px'}
      />
    </Stack>
  );
}
