import { Box, Stack, Text, VStack } from '@chakra-ui/react';
import CustomNextImage from '@components/ChakraNextImage';

export default function Winner() {
  return (
    <Stack
      p={5}
      bgColor="#c2c3c9"
      align={'center'}
      justify={'center'}
      spacing={10}
    >
      <Stack
        direction={['column', 'row']}
        align={'center'}
        w={'90vw'}
        justify={'space-between'}
      >
        <CustomNextImage
          src={'/imgs/pizza_hut/winner.png'}
          alt={`winner.png`}
          width={'389px'}
          height={'142px'}
        />
        <Box>
          <CustomNextImage
            src={'/imgs/pizza_hut/logo_red.png'}
            alt={`logo_red.png`}
            width={'274px'}
            height={'163px'}
          />
        </Box>
      </Stack>
      <VStack
        align={'center'}
        textAlign={'center'}
        fontSize={['lg', 'lg', '20px', '28px']}
        spacing={0}
        color={'#A10000'}
        fontWeight={'bold'}
      >
        <Text fontSize={['2xl', '35px', '50px', '60px']} fontWeight={900}>
          THIAGO LOURO FORESI
        </Text>
        <p>CPF: XXX.XXX.X98-08</p>
        <p>CIDADE: FORTALEZA - CE</p>
        <p>LOJA PIZZA HUT - AEROPORTO DE FORTALEZA</p>
      </VStack>
    </Stack>
  );
}
