import { DynamicFieldData } from '@interfaces/games/FormGame';
import {
  // validateCNPJ,
  validateCPF,
  validateDate,
} from '@lib/helpersValidation';
import { messageTextAreaName } from './conts';

const ENV = process.env.NEXT_PUBLIC_NODE_ENV;

export const fields: DynamicFieldData[] = [
  {
    fieldName: 'nome',
    inputType: 'text',
    label: 'Nome',
    defaultValue: '',
    config: {
      required: 'Nome é obrigatório',
    },
  },
  {
    fieldName: 'cpf',
    inputType: 'text',
    label: 'CPF',
    defaultValue: '',
    mask: '999.999.999-99',
    placeholder: '000.000.000-00',
    config: {
      required: 'CPF é obrigatório',
      validate: (value) => !validateCPF(value) || 'CPF não é válido',
    },
    formatOut: (value: string) => {
      return value.replaceAll('.', '').replaceAll('-', '');
    },
  },
  {
    fieldName: 'msisdn',
    inputType: 'text',
    label: 'Celular',
    defaultValue: '',
    mask: '(99) 99999-9999',
    placeholder: '(12) 91234-1234',
    config: {
      required: 'Telefone é obrigatório',
      validate: (value) =>
        !(value.replaceAll('_', '').length < 15) || 'Telefone não é válido',
    },
    formatOut: (value: string) => {
      return value
        .replaceAll('(', '')
        .replaceAll(')', '')
        .replaceAll(' ', '')
        .replaceAll('-', '');
    },
  },
  {
    fieldName: 'estadoCivil',
    inputType: 'select',
    label: 'Estado Civil',
    options: [
      { value: 'Solteiro', label: 'Solteiro' },
      { value: 'Casado', label: 'Casado' },
      { value: 'Divorciado', label: 'Divorciado' },
      { value: 'Viúvo', label: 'Viúvo' },
    ],
    defaultValue: 'Solteiro',
  },
  {
    fieldName: 'dtNascimento',
    inputType: 'text',
    label: 'Data de Nascimento',
    mask: '99/99/9999',
    placeholder: 'dd/mm/yyyy',
    defaultValue: '',
    config: {
      required: 'Data de Nascimento é obrigatório',
      validate: (value) => !validateDate(value) || 'Data não é válida',
    },
    formatOut: (value: string) => {
      const [d, m, y] = value.split('/');
      return `${y}-${m}-${d}`;
    },
  },
];

export const fieldsValidate: DynamicFieldData[] = [
  {
    fieldName: 'nome',
    inputType: 'text',
    label: 'Nome Completo',
    defaultValue: '',
    maxLength: 250,
    config: {
      required: 'Nome é obrigatório',
      validate: (value: string) =>
        (value.includes(' ') && value.length < 250) || 'Nome não é válido',
    },
    fieldWidth: { base: '100%', lg: 300 },
  },
  {
    fieldName: 'cpf',
    inputType: 'text',
    label: 'CPF',
    defaultValue: '',
    mask: '999.999.999-99',
    placeholder: '000.000.000-00',
    config: {
      required: 'CPF é obrigatório',
      validate: (value) =>
        validateCPF(value.replaceAll('.', '').replaceAll('-', '')) ||
        'CPF não é válido',
    },
    formatOut: (value: string) => {
      return value.replaceAll('.', '').replaceAll('-', '');
    },
    fieldWidth: { base: '100%', lg: 300 },
  },
  {
    fieldName: 'msisdn',
    inputType: 'text',
    label: 'Celular',
    defaultValue: '',
    mask: '(99) 99999-9999',
    placeholder: '(12) 91234-1234',
    config: {
      required: 'Telefone é obrigatório',
      validate: (value) =>
        !(value.replaceAll('_', '').length < 15) || 'Telefone não é válido',
    },
    formatOut: (value: string) => {
      return value
        .replaceAll('(', '')
        .replaceAll(')', '')
        .replaceAll(' ', '')
        .replaceAll('-', '');
    },
    fieldWidth: { base: '100%', lg: 300 },
  },
  {
    fieldName: 'dtNascimento',
    inputType: 'text',
    label: 'Data de Nascimento',
    mask: '99/99/9999',
    placeholder: 'dd/mm/yyyy',
    defaultValue: '',
    config: {
      required: 'Data de Nascimento é obrigatório',
      validate: (value) => !validateDate(value) || 'Data não é válida',
    },
    formatOut: (value: string) => {
      const [d, m, y] = value.split('/');
      return `${y}-${m}-${d}`;
    },
    fieldWidth: { base: '100%', lg: 300 },
  },
];

export const fieldsContact: DynamicFieldData[] = [
  {
    fieldName: 'name',
    inputType: 'text',
    label: '1 - NOME ',
    defaultValue: '',
    maxLength: 250,
    config: {
      required: 'Nome é obrigatório',
      // validate: (value: string) =>
      //   (value.includes(' ') && value.length < 250) || 'Nome não é válido',
    },
    fieldWidth: { base: '100%', lg: 400 },
  },
  {
    fieldName: 'email',
    inputType: 'text',
    label: '2 - E-MAIL ',
    defaultValue: '',
    maxLength: 250,
    config: {
      required: 'Digite seu email',
      pattern: {
        value:
          ENV == 'production'
            ? /^[aA-zZ0-9._%-]+@[aA-zZ0-9.-]+\.[aA-zZ]{2,4}$/
            : /^[aA-zZ0-9._%-+]+@[aA-zZ0-9.-]+\.[aA-zZ]{2,4}$/,
        message: 'Digite um e-mail válido',
      },
      // required: 'Nome é obrigatório',
      // validate: (value: string) =>
      //   (value.includes(' ') && value.length < 250) || 'Nome não é válido',
    },
    fieldWidth: { base: '100%', lg: 400 },
  },
  {
    fieldName: 'msisdn',
    inputType: 'text',
    label: '3 - TELEFONE ',
    defaultValue: '',
    maxLength: 250,
    mask: '(99) 99999-9999',
    // placeholder: '(12) 91234-1234',
    config: {
      required: 'Telefone é obrigatório',
      validate: (value) =>
        !(value.replaceAll('_', '').length < 15) || 'Telefone não é válido',
    },
    formatOut: (value: string) => {
      return value
        .replaceAll('(', '')
        .replaceAll(')', '')
        .replaceAll(' ', '')
        .replaceAll('-', '');
    },
    fieldWidth: { base: '100%', lg: 400 },
  },
  {
    fieldName: 'subject',
    inputType: 'text',
    label: '4 - ASSUNTO ',
    defaultValue: '',
    maxLength: 250,
    config: {
      required: 'Assunto é obrigatório',
      // validate: (value: string) =>
      //   (value.includes(' ') && value.length < 250) || 'Nome não é válido',
    },
    fieldWidth: { base: '100%', lg: 400 },
  },
  {
    fieldName: messageTextAreaName,
    inputType: 'textarea',
    label: '5 - MENSAGEM ',
    defaultValue: '',
    maxLength: 250,
    config: {
      required: 'Mensagem é obrigatória',
      // required: 'Nome é obrigatório',
      // validate: (value: string) =>
      //   (value.includes(' ') && value.length < 250) || 'Nome não é válido',
    },
    fieldWidth: 'full',
    fieldHeight: '100px',
  },
];
