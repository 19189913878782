import { Box, HStack, VStack, Link } from '@chakra-ui/react';
import CustomNextImage from '@components/ChakraNextImage';
import { getMobileOS } from '@lib/helpers';
import { useEffect, useState } from 'react';

export default function HowToPlay() {
  const [urlClick, setUrlClick] = useState('');
  useEffect(() => {
    const mobile = getMobileOS();
    switch (mobile) {
      case 'Android':
        setUrlClick(
          'intent://pizzahut.imc.com/#Intent;scheme=pizzahut;package=com.imc.pizzahut.app;end'
        );
        setTimeout(() => {
          setUrlClick(
            'https://play.google.com/store/apps/details?id=com.imc.pizzahut'
          );
        }, 3000);
        break;
      case 'Safari':
      case 'iOS':
        setUrlClick(
          'https://apps.apple.com/br/app/pizza-hut-brasil-imc/id1673115073'
        );

        break;
      case 'Other':
      default:
        setUrlClick(
          'https://play.google.com/store/apps/details?id=com.imc.pizzahut'
        );
        break;
    }
  }, []);

  return (
    <VStack spacing={{ base: '10px', lg: '32px' }}>
      <Box height={'2px'} bgColor="white" width={'60vw'} />
      <HStack align={'center'} justify="center">
        <VStack
          display={{ base: 'none', lg: 'flex' }}
          spacing={{ base: '10px', lg: '32px' }}
        >
          <CustomNextImage
            src={'/imgs/pizza_hut/howparticipate.png'}
            alt={`howparticipate.png`}
            width={'1439x'}
            height={'652px'}
          />
          <Box height={'2px'} bgColor="white" width={'60vw'} />
          <Link href={urlClick} target="_blank" p={0} lineHeight={0}>
            <CustomNextImage
              src={'/imgs/pizza_hut/click.png'}
              alt={`click.png`}
              width={'1440x'}
              height={'603px'}
            />
          </Link>
        </VStack>
        <VStack display={{ base: 'flex', lg: 'none' }} spacing={10}>
          <Box p={1}>
            <CustomNextImage
              src={'/imgs/pizza_hut/how_participate_mobile.png'}
              alt={`how_participate_mobile.png`}
              width={'830x'}
              height={'1108px'}
            />
          </Box>
          <Box height={'2px'} bgColor="white" width={'60vw'} />
          <Link href={urlClick} target="_blank" p={0} lineHeight={0} pr={2}>
            <CustomNextImage
              src={'/imgs/pizza_hut/click_mobile.png'}
              alt={`click_mobile.png`}
              width={'950x'}
              height={'603px'}
            />
          </Link>
        </VStack>
      </HStack>
    </VStack>
  );
}
