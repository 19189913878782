import {
  Box,
  Button,
  HStack,
  Stack,
  VStack,
  useMediaQuery,
  useToast,
} from '@chakra-ui/react';
import CustomNextImage from '@components/ChakraNextImage';
import { motion } from 'framer-motion';
import { useSession } from 'next-auth/react';
import { useRouter } from 'next/router';

export default function Header() {
  const router = useRouter();
  const toast = useToast();
  const { status } = useSession();
  const [isMobile] = useMediaQuery('(max-width: 992px)');

  const DaUmHut = () => {
    return (
      <VStack mr={{ base: '10', lg: '0' }}>
        <CustomNextImage
          src={'/imgs/pizza_hut/daumhut.png'}
          alt={`Da Um hut`}
          width={'350px'}
          height={'442px'}
        />{' '}
        <CustomNextImage
          src={'/imgs/pizza_hut/logoDescripition.png'}
          alt={`Da Um hut`}
          width={'205px'}
          height={'119px'}
        />
      </VStack>
    );
  };

  const Player = () => {
    return (
      <VStack h={'full'} justify={'end'}>
        <CustomNextImage
          src={'/imgs/pizza_hut/player.png'}
          alt={`Player`}
          width={'557px'}
          height={'576px'}
        />
      </VStack>
    );
  };

  const Explanation = () => {
    return (
      <VStack
        justify={'space-around'}
        alignSelf={{ base: 'center', lg: 'center' }}
        spacing={5}
        px={2}
      >
        <CustomNextImage
          src={'/imgs/pizza_hut/descriptionbuy.png'}
          alt={`description buy`}
          width={'397px'}
          height={'372px'}
        />

        <Box>
          <Button
            variant="formButton"
            as={motion.button}
            dragConstraints={{ left: -100, right: 100 }}
            whileHover={{ scale: 1.2 }}
            whileTap={{ scale: 0.9 }}
            transition="0.05s linear"
            onClick={() => {
              if (status != 'authenticated') {
                router.push('/login');
              } else {
                toast({
                  title: 'Participação encerrada! ',
                  description: `Período da promoção de 19/12/2023 até 29/01/2024.`,
                  status: 'warning',
                  duration: 9000,
                  isClosable: true,
                  position: 'top',
                });
              }
            }}
          >
            {status != 'authenticated' ? 'ENTRAR' : 'CADASTRAR'}
          </Button>
          {/* <CustomNextImage
          src={'/imgs/pizza_hut/btnEntrar.png'}
          alt={`btn entrar`}
          width={'209px'}
          height={'77px'}
        /> */}
        </Box>
      </VStack>
    );
  };

  if (!isMobile) {
    return (
      <VStack>
        <HStack
          justify={'space-between'}
          align={'center'}
          maxW={'1440px'}
          w={'full'}
        >
          <HStack h={'full'} align="end">
            <DaUmHut />
            <Player />
          </HStack>
          <Explanation />
        </HStack>
      </VStack>
    );
  }

  return (
    <VStack>
      <Stack
        justify={{ base: 'center', lg: 'space-between' }} // lg- 992px
        align={'center'}
        direction={{ base: 'column', lg: 'row' }}
        maxW={'1440px'}
        w={'full'}
        spacing={5}
      >
        <DaUmHut />
        <Box height={'2px'} bgColor="white" width={'60vw'} />
        <Explanation />
        <Box height={'2px'} bgColor="white" width={'60vw'} />
        <Player />
      </Stack>
    </VStack>
  );
}
