export const getMobileOS = () => {
  const ua = navigator.userAgent;

  if (/android/i.test(ua)) {
    return 'Android';
  } else if (
    /iPad|iPhone|iPod/.test(ua) ||
    (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)
  ) {
    return 'iOS';
  }else if( /^((?!chrome|android).)*safari/i.test(navigator.userAgent)){
    return 'Safari'
  }

  return 'Other';
};



export function isJsonString(str:string) {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
  }
  